<template>
  <div>
    <br />
    <div
      class="app-page-spinner"
      v-if="loading"
      v-loading="loading"
    ></div>

    <el-form
      :label-position="labelPosition"
      :label-width="labelWidthForm"
      @submit.prevent.native
      class="form"
      v-if="record"
    >
      <app-view-item-text
        :label="fields.fundingEnum.label"
        :value="presenter(record, 'fundingEnum')"
      ></app-view-item-text>

      <app-view-item-relation-to-many
        :label="fields.grants.label"
        :readPermission="fields.grants.readPermission"
        :navigatePermission="fields.grants.navigatePermission"
        :url="fields.grants.viewUrl"
        :value="presenter(record, 'grants')"
      ></app-view-item-relation-to-many>

      <app-view-item-text
        :label="fields.accountingProjectNo.label"
        :value="presenter(record, 'accountingProjectNo')"
      ></app-view-item-text>
    </el-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ProjectFundingModel } from '@/modules/project-funding/project-funding-model';

const { fields } = ProjectFundingModel;

export default {
  name: 'app-project-funding-view-page',

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      record: 'projectFunding/view/record',
      loading: 'projectFunding/view/loading',
      projectFundingId: 'project/form/projectFundingId',
    }),

    fields() {
      return fields;
    },
  },

  async created() {
    await this.doFind(this.projectFundingId);
  },

  methods: {
    ...mapActions({
      doFind: 'projectFunding/view/doFind',
    }),

    presenter(record, fieldName) {
      return ProjectFundingModel.presenter(record, fieldName);
    },
  },
};
</script>
